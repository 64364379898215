import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap'
import 'jquery/src/jquery'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar';
import Home from './components/home';
import Footer from './components/footer';
import Contact from './components/contact';
import Newscoverage from './components/newscoverage';
import Young from './components/young';
import Startup from './components/startup';
import Loveaction from './components/loveaction'
import Education from './components/education'
import Medimpact from './components/medimpact';
import Nature from './components/nature';
import Holyabode from './components/holyabode';
import Religiousconnect from './components/religiousconnect';
function App() {
  return (
<>
<Router>
  <Navbar/>
  <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/young' element={<Young/>}/>
    <Route path='/newscoverage' element={<Newscoverage/>}/>
    <Route path='/medimpact' element={<Medimpact/>}/>
    <Route path='/nature' element={<Nature/>}/>
    <Route path='/startup' element={<Startup/>}/>
    <Route path='/religiousconnect' element={<Religiousconnect/>}/>
    <Route path='/loveaction' element={<Loveaction/>}/>
    <Route path='/education' element={<Education/>}/>
    <Route path='/holyabode' element={<Holyabode/>}>
    </Route>
  </Routes>
  <Footer/>
</Router>
</>
  );
}

export default App;
