import React, {useState} from 'react'
import img from '../images/holyAbode.jpg'
import { Holy } from './DataImages'
import ImageModal from './ImageModal'
const Holyabode = () => {
  const [holyOpen, setHolyOpen]= useState(false)
  const [index, setIndex] = useState(0)
  const setHolyIndex = (index) => {
    setIndex(index)
    setHolyOpen(Holy)
  }
  return (
    <>
      <div classname="section "  >
        <div className='heading-section  ' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>Holy Abode</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>Holy Abode</a>
          </span>
        </div>
      </div>

      {/* holy abode */}
      <div className='py-5 position-relative'>
      <ImageModal source={Holy} index={index} requestToClose={setHolyOpen} requestToOpen={holyOpen} />
        <div className='container'>
          <h1 className='fw-bold text-center mb-4'>
            Holy Abode
          </h1>
          <p className='text-secondary fw-semibold'>Restful haven for pilgrims, crafted with care Mehandipur Balaji (Dausa) in Rajasthan is a center of reverence and faith for people not only in the country but all over the world. Late Shri Padam Kumar Dalmia was an ardent devotee of Lord Hanuman.  Recognising the need for a place for comfortable stay for the pilgrims he constructed a dharamshala “Anjani dham”.  The dharamshala is befittingly named after Lord Hanuman's mother. Just like the lap of a mother, this dharamshala offers solace and comfort to weary travelers on their spiritual journey. The travellers are welcomed with open hearts, where they are provided a peaceful retreat, nourishing both body and soul at very reasonable prices.</p>
    
{/* holy */}
      <div className='eye-camp py-5'>
        <div className='gallery-box1'>
          {Holy.map((item, index) => (
            <div className='image-box1'>
              <img src={item.img} alt='' className='img-fluid' onClick={() => setHolyIndex(index)}/>
            </div>
          ))}
        </div>
      </div>


      {/* address */}
      <div>
      <h3 className=' fw-bold mb-4'>Book a Room </h3>
      <h5 className='fw-bold'>Address:</h5>
        <h5 >Anjanidham</h5>
        <h5>Mehndipur Balaji (Distt. Dausa) Rajasthan - 321610</h5><br/>
        <h5 className='fw-bold'>Contact Number:</h5>
        <h5><i class="fa-solid fa-tty fs-4"></i> 01420-247184, 01420-203059</h5>
        <h5><i class="fa-solid fa-phone fs-4"></i> 94144-07481</h5>
      </div>
      </div>
      </div>
    </>

  )
}

export default Holyabode
