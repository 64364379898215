import React from 'react'
import logo from '../images/mahabir prasad dalmia  FINAL.png'
import './home.css'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light ">
        <div className="container">
          <a className="navbar-brand " href="/">
            <img src={logo} alt='' width={130} className='img-fluid' />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav  ms-auto fs-4 fw-semibold">
              <li className="nav-item">
                <Link className="nav-link active me-3" aria-current="page" to="/">Home</Link>

              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle me-3" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Activities
                </a>
                <ul className="dropdown-menu ">
                  <li><Link className="dropdown-item" to="/medimpact">Medimpact</Link></li>
                  <li><Link className="dropdown-item" to="/nature">Nature Ally</Link></li>
                  <li><Link className="dropdown-item" to="/loveaction">Love in Action</Link></li>
                  <li><Link className="dropdown-item" to="/education">Educare</Link></li>
                  <li><Link className="dropdown-item" to="/religiousconnect">Religious Connect</Link></li>
                  <li><Link className="dropdown-item" to="/holyabode">Holy Abode</Link></li>
                  <li><Link className="dropdown-item" to="/startup">Fundraiser Startup</Link></li>

                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link  me-3" to="/young">Young Volunteers</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link  me-3" to="/newscoverage">News Coverage</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link me-3 " to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>

        </div>
      </nav>

    </>
  )
}


export default Navbar
