import React, { useState, useEffect } from 'react'
import './contact.css'
import services from '../Backend/services'
import img from '../images/Contact-us.jpg'
const Contact = () => {

  const [userData, setUserData] = useState({
    Firstname: "",
    Lastname: "",
    phone: "",
    email: ""
  });

  const [error, setError] = useState({ error: false, msg: '' })
  const submitForm = async (e) => {
    e.preventDefault();
    if (!userData.email || !userData.Firstname || !userData.phone) {

      return setError({ error: true, msg: 'Please fill all input feilds.' })
    }
    if (userData.phone.length < 10 || userData.phone.length > 10) {
      return setError({ error: true, msg: 'Invalid phone number.' });
    }
    let data = { Firstname: userData.Firstname, Lastname: userData.Lastname, phone: userData.phone, email: userData.email }
    await services
      .postCountries(data)
      .then(() => {
        setError({ error: false, msg: 'Your form is submitted.' })
      })
      .catch((error) => {
        setError({ error: true, msg: error.message })
      });
  };

  useEffect(() => {
  }, [])

  return (
    <>
      <div classname="section">
        <div className='heading-section' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>Contact Us</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>Contact</a>
          </span>
        </div>
      </div>

      {/* form */}
      <div className='contact-detail py-5 bg-light'>
        <div className='container'>
          <div className='form-detail border shadow-lg p-5 mb-5 bg-body rounded'>
            <h1 className='text-center mb-5 fw-bold'>Get In Touch With Us</h1>
            <form className='fs-5' onSubmit={submitForm}>
              <div className='row'>
                {error.msg && <div className={`alert alert-${error.error ? 'danger' : 'success'} alert-dismissible fade show`} role="alert">{error.msg}
                  <button onClick={() => { setError({ error: false, msg: '' }) }} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                </div>
                }
                <div className='col-lg-6 mb-3'>
                  <label htmlFor="exampleInputEmail1" className="form-label">First Name*</label>
                  <input value={userData.Firstname}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        Firstname: e.target.value,
                      });
                    }} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className='col-lg-6 mb-3'>
                  <label htmlFor="exampleInputEmail1" className="form-label">Last Name*</label>
                  <input value={userData.Lastname}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        Lastname: e.target.value,
                      });
                    }} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className='col-lg-6 mb-3'>
                  <label htmlFor="exampleInputEmail1" className="form-label">Phone Number*</label>
                  <input value={userData.phone}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        phone: e.target.value,
                      });
                    }} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className='col-lg-6 mb-3'>
                  <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                  <input value={userData.email}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        email: e.target.value,
                      });
                    }} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>

                <div className="col-lg-12 mb-3">
                  <label htmlFor="floatingTextarea2">Message</label>
                  <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: 100 }} defaultValue={""} />
                </div>
              </div>
              <div className='button text-center mt-3'>
                <button type="submit" className="btn fs-5 fw-bold rounded-5 px-5 py-3 btn-outline-dark">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* map */}
      <div className='container-fluid'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6847.256000704351!2d75.85179413493708!3d30.8970659414729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a8300fd4d2205%3A0xc4cf1812b51a3be8!2sMiller%20Ganj%2C%20Ludhiana%2C%20Punjab!5e0!3m2!1sen!2sin!4v1694767444314!5m2!1sen!2sin" height={500} style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='w-100'></iframe>
      </div>
    </>
  )
}

export default Contact
