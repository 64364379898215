import React, { useState } from 'react'
import img from '../images/Nature-ally.jpg'
import { Vaccination, birds, vrindavan, gaushala } from './DataImages'
import ImageModal from './ImageModal'
const Nature = () => {
  const [gaushalaOpen, setGaushalaOpen] = useState(false)
  const [vrindavanOpen, setVrindavanOpen] = useState(false)
  const [birdsOpen, setBirdsOpen] = useState(false)
  const [vaccinationOpen, setVaccinationOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const setGaushalaIndex = (index) => {
    setIndex(index)
    setGaushalaOpen(!gaushalaOpen)
  }

  const setVrindavanIndex = (index) => {
    setIndex(index)
    setVrindavanOpen(!vrindavanOpen)
  }
  const setBirdsIndex = (index) => {
    setIndex(index)
    setBirdsOpen(!birdsOpen)
  }
  const setVacinationIndex = (index) => {
    setIndex(index)
    setVaccinationOpen(!vaccinationOpen)
  }

  return (
    <>
      <div classname="section "  >
        <div className='heading-section  ' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>Nature Ally</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>Nature Ally</a>
          </span>
        </div>
      </div>

      {/* nature */}
      <div className='py-4 position-relative'>
        <ImageModal source={gaushala} index={index} requestToClose={setGaushalaOpen} requestToOpen={gaushalaOpen} />
        <ImageModal source={vrindavan} index={index} requestToClose={setVrindavanOpen} requestToOpen={vrindavanOpen} />
        <ImageModal source={birds} index={index} requestToClose={setBirdsOpen} requestToOpen={birdsOpen} />
        <ImageModal source={Vaccination} index={index} requestToClose={setVaccinationOpen} requestToOpen={vaccinationOpen} />

        <div className='container'>
          <h2 className='fw-bold text-center mb-4 text-dark'>
            Nature Ally
          </h2>
          <p className='text-secondary fw-semibold'>Championing animal welfare with compassion. Our dedicated charity has constructed safe havens, like shelters, sheds in gaushala, providing our animal friends a secure place to thrive. Through regular feeding and care, they are nurturing their well-being and creating a haven of love. Together, we can make a positive impact on the lives of animals in need, providing them with the love and care they deserve. Join us in creating a brighter future for our animal friends.</p>


          {/* gaushala */}
          <div className='eye-camp py-4'>
            <h4 className='text-center fw-bold mb-4  '>Prachin Gaushala, Part-II, Tibba Road, Ludhiana</h4>
            <div className='gallery-box1'>
              {gaushala.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setGaushalaIndex(index)} />
                </div>
              ))}
            </div>
          </div>
          {/* vrindavan */}
          <div className='eye-camp py-4'>
            <h4 className='text-center fw-bold mb-4'>Govind Godham, Vrindavan</h4>
            <div className='gallery-box1'>
              {vrindavan.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setVrindavanIndex(index)} />
                </div>
              ))}
            </div>
          </div>
          {/* free vaccination */}
          <div className='eye-camp py-4'>
            <h4 className='text-center fw-bold mb-4 '>Free Vaccinations for Street Dogs </h4>
            <div className='gallery-box1'>
              {Vaccination.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setVacinationIndex(index)} />
                </div>
              ))}
            </div>
          </div>

          {/* birds */}
          <div className='eye-camp py-4'>
            <h4 className='text-center fw-bold mb-4 '>Freeing Caged Birds</h4>
            <div className='gallery-box1'>
              {birds.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setBirdsIndex(index)} />
                </div>
              ))}
            </div>
          </div>






        </div>
      </div>
    </>
  )
}

export default Nature
