import React, { useState } from 'react'
import img from '../images/Maidimpact.jpg'

import { eyeCamp, prostheticCamp, limpCamp } from './DataImages'
import './medimapct.css'
import ImageModal from './ImageModal'
const Medimpact = () => {
  const [open, setOpen] = useState(false)
  const [openProstethic, setOpenProstethic] = useState(false)
  const [LimpcampOpen, setLimpcampOpen] =useState(false)
  const [index, setIndex] = useState(0)

  const setImageIndex = (index) => {
    setOpen(!open)
    setIndex(index)
  }
  const setProstheticImageIndex = (index) => {
    setOpenProstethic(!openProstethic)
    setIndex(index)
  }
  const setLimpcampIndex = (index) => {
    setIndex(index)
    setLimpcampOpen(!LimpcampOpen)
  }
  return (
    <>
      <div classname="section "  >
        <div className='heading-section  ' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>Medimpact</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>Medimpact</a>
          </span>
        </div>
      </div>

      {/* heading */}
      <div className='py-5 position-relative'>
        <ImageModal source={eyeCamp} requestToOpen={open} index={index} requestToClose={setOpen} />
        <ImageModal source={prostheticCamp} requestToOpen={openProstethic} index={index} requestToClose={setOpenProstethic} />
        <ImageModal source={limpCamp} index={index} requestToClose={setLimpcampOpen} requestToOpen={LimpcampOpen} />
        <div className='container'>
          <h2 className='text-center fw-bold mb-4 text-dark text-decoration-underline'>Medimpact</h2>
          <p className='text-secondary fw-semibold'>Restoring hope and changing lives. Our charity extends a helping hand through free medical camps in Mehandipur, Dausa where even day to day basic medical care is hard to come by. Medical camps such as artificial limb camps have enabled mobility and independence for those in need. Additionally, our free eye camps brought the gift of sight to many, unveiling a world of possibilities. All the camps are organised and managed by the youth wing of the trust, under the supervision of the trustees.  This is done to foster socially conscious empowered citizens of tomorrow. Together, we're fostering empowerment and a future full of opportunities.</p><br />




          <h5 className='fw-bold'>Artificial Limb Camp - 2024</h5>
          <p className='text-secondary fw-semibold'>Successful Artificial Limb Camp Empowers Limb Difference Community Mahabir Prasad Dalmia Jan Seva Trust under the leadership of Samarthya Bindlish, a class X student of Vivek High School hosted a highly successful Artificial Limb Camp, bringing together individuals with limb differences for a day of empowerment and support. He was assisted by young pioneers Ananya Chowdhery, Advay Chowdhery, Shanaya Juneja and Viraj Dalmia.  Held at Bharat Vikas Parishad Charitable Trust Punjab, the camp provided attendees with invaluable resources, expert guidance, and a dignified life. The camp was held in the memory of the founder of the trust Shri Padam Kumar Dalmia on his 4th death anniversary. The young volunteers played a proactive role in collecting the funds, organising the camp and assisting the medical team in their work.
            The camp impacted countless lives by giving the gift of limb and dignity.   In the camp 25 people benefited and received 28 artificial limbs( calipers , upper limbs, upper and lower limbs, hearing aids and tricycles)
          </p>
          <p className='text-secondary fw-semibold'>
            The success of the Artificial Limb Camp underscores the importance of fostering inclusivity, empowerment, and accessibility within the limb difference community. Mahabir Prasad Dalmia Jan Seva Trust remains dedicated to championing these values and supporting individuals on their journey toward enhanced mobility and independence.</p>
          {/* limp camp */}
          <div className='eye-camp py-4'>
            <div className='gallery-box1'>
              {limpCamp.map((item, index) => (
                <div className='image-box1' key={index}>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setLimpcampIndex(index)} />
                </div>
              ))}
            </div>
          </div>



          <h5 className='fw-bold mt-4'>Eye Camp - March 2023</h5>
          <p className='text-secondary fw-semibold'>
            Our free eye camp focused on a different facet of restoration - the gift of sight. We recognized the profound impact that vision has on one's life and experiences. With state-of-the-art medical care and a team of dedicated professionals of “Dr. Shroff Eye Hospital, Alwar” we provided essential eye examinations, spectacles, treatments (medicines and injections), and cataract surgeries to those who lack access to such services. The youth wing under the leadership of Ananya Chowdhery and Samarthya Bindlish, took upon themselves to spread awareness about basic eye care and the camp. They visited 6 government and private schools, covered 7 nearby villages on foot and talked to innumerable villagers about eye care and proper use of spectacles and cataract identification. The trust managed  to touch 10000 lives, 500 + prescription glasses were given free of cost and 129 cataract surgeries were performed in adults and children by Shroff hospital. The funds for the same were provided for by the trust. The ripple effect of improved vision is immeasurable, touching families, communities, and the larger world. <br /> <br />

            Together, these endeavours underline our commitment to inclusivity and well-being. Beyond medical interventions, our camps radiate positivity and the belief that a brighter future is attainable, regardless of circumstances. With every limb fitted and every eye healed, our charity moves forward, driven by the conviction that compassion and collective efforts can truly change lives.
          </p>
          {/* eye camp */}
          <div className='eye-camp py-4'>
            <div className='gallery-box1'>
              {eyeCamp.map((item, index) => (
                <div className='image-box1' key={index}>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setImageIndex(index)} />
                </div>
              ))}
            </div>
          </div>

          <h5 className='fw-bold'>Prosthetics Camp - February 2021</h5>
          <p className='text-secondary fw-semibold'>
            In a world where opportunities are often hindered by physical limitations, our charity stands as a beacon of hope, conducting impactful initiatives that transform lives. The services of doctors and technicians of “Bharat Vikas Parishad, Ludhiana were availed to conduct the camp.  Fund collection and organisation of the camp was done remotely by the young brigade.  But their physical presence was not possible due to Covid-19 restrictions. Through our free artificial limb camp, we bridged the gap for individuals who have faced the loss of a limb, providing them with not just prosthetics, but a chance to regain their independence and confidence. The camp became a platform of empowerment, enabling them to walker, work, and reintegrate into society with renewed vigor. Through this camp we managed to touch 200+ lives with artificial limbs, hearing aid, wheel chair, crutches, walker and polio shoes and braces.
          </p>

          {/* prosthetic camp */}
          <div className='eye-camp py-5'>
            <div className='gallery-box1'>
              {prostheticCamp.map((item, index) => (
                <div className='image-box1' key={index}>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setProstheticImageIndex(index)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default Medimpact
