import React from 'react'
import img from '../images/educare.jpg'
const Education = () => {
  return (
    <>

      <div classname="section "  >
        <div className='heading-section  ' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>EduCare</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>Education</a>
          </span>
        </div>
      </div>

      {/* education */}
      <div className='education py-5'>
        <div className='container'>
          <h1 className='fw-bold text-center mb-4'>EduCare</h1>
          <p className='text-secondary fw-semibold'>Illuminating pathways through education. Our charity passionately sponsors underprivileged students, opening doors to knowledge and a brighter future. By conducting skill-based workshops, we empower these young minds to flourish and contribute to society. Join us in bridging gaps, transforming lives, and shaping a world of endless possibilities.<br /><br />
            Our young volunteers helped to fund education, uniform and books for Jaspreet Singh studying in Adarsh Public School, Ludhiana from Grades 3-10. Tutored him online in Steam subjects including Math, Computer and English. Hosted workshops in Adarsh Public School on nuances of Math, Computer and English Grammar (by integrating Hindi syllables for easier understanding).<br /><br />Taught maths and computers to students at Adarsh Public School, Ludhiana – Used various tricks and practical examples to captivate the attention of students and help them overcome their fears.
          </p>
        </div>
      </div>
    </>
  )
}

export default Education
