import React from 'react'
import img from '../images/News-Coverage.jpg'
import news1 from '../images/news1.jpg'
import news2 from '../images/news2.webp'
import news3 from '../images/news3.png'
import news4 from '../images/news4.jpg'
import news5 from '../images/news5.png'
import news6 from '../images/news6.png'
import recognition1 from '../Recognitions/Bharat Vikas Parishad, Ludhiana- and  Bharat Bhushan Ashu.jpg'
import recognition2 from '../Recognitions/10.jpg'
import recognition3 from '../Recognitions/3.jpg'

const Newscoverage = () => {
  return (
    <>
      <div classname="section "  >
        <div className='heading-section  ' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>News Coverage</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>News Coverage</a>
          </span>
        </div>
      </div>

      {/* news */}
      <div className='news py-4'>
        <div className='container'>
          <h2 className='fw-bold text-center text-dark text-decoration-underline'>News Articles</h2>

          <div className='row py-4'>
            <div className='col-lg-6 '>
              <img src={news5} alt='' className='img-fluid border ' />
            </div>
            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
              <div>
                <h5 className='fw-bold'>कैंप में 25 दिव्यांगों को वितरित किए 28 कृत्रिम अंग</h5>
                <h5 className='lh-base'><i class="fa-solid fa-calendar-days"></i> &nbsp;  18 फरवरी, 2024</h5>
                <p>लुधियाना, 18 फरवरीः महावीर प्रसाद जन सेवा ट्रस्ट ने भारत विकास परिषद चैरिटेबल ट्रस्ट पंजाब के सहयोग से रिशी नगर स्थित दिव्यांग सहायता केंद्र में निःशुल्क कृत्रिम अंगदान वितरण कैंप का आयोजन किया। कैंप में युवा अग्रदूतो, अनन्या चौधरी, अद्वय चौधरी, शनाया जुनेजा और विराज डालमिआ द्वारा दिव्यांगों की मदद की गई। युवा अग्रदूत ने धन संग्रह आयोजन
                  ....ताकि बिना किसी सहारे के अपने कदमों पर चल सकें
                  दिव्यांगों को 28 निःशुल्क कृत्रिम अंग बाजू, कैलिपर, बैसाखी, ट्राईसाइकिल व ऊंचा सुनने के लिए कानों की मशीन वितरित की गई। कैंप में मुख्य मेहमान के तौर पर पद्मश्री रजनी बैक्टर ने शिरकत की, जबकि तृप्ता डालमिआ व डॉ. आर. एल नारंग विशेष मेहमान के तौर पर उपस्थित रहे। भारत विकास परिषद के अध्यक्ष पंकज जिंदल ने उपस्थित
                  सदस्य । </p>
                <a href='https://epaper.punjabkesari.in/subscription'>https://epaper.punjabkesari.in/subscription</a>
              </div>
            </div>
          </div>

          <div className='row py-4'>
            <div className='col-lg-6 '>
              <img src={news6} alt='' className='img-fluid border ' />
            </div>
            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
              <div>
                <h5 className='fw-bold'>Ludhiana Hots Free Artificial Limbs Distribution Camp</h5>
                <h5 className='lh-base'><i class="fa-solid fa-calendar-days"></i> &nbsp;  18 February, 2024</h5>
                <p>LUDHIANA: Ludhiana saw a gesture of community support as Mahabir Prasad Dalmia Jan Seva Trust, in collaboration with Bharat Vikas Parishad Charitable Trust (BVPCT) Pun- jab, organised a free artificial. limbs distribution camp at Div- yang Sahayata Kender, Rishi Nagar. During the free artificial limbs distribution camp, as many as 28 artificial limbs, gation they including calipers, upper limbs iate before and hearing aids, were distrib- ansaction uted, benefiting 25 individuals. </p>
                <a href='https://epaper.punjabkesari.in/subscription'>https://epaper.punjabkesari.in/subscription</a>
              </div>
            </div>
          </div>

          <div className='row py-4'>
            <div className='col-lg-6 '>
              <img src={news4} alt='' className='img-fluid border ' />
            </div>
            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
              <div>
                <h5 className='fw-bold'>कैंप में 25 दिव्यांगों को वितरित किए 28 कृत्रिम अंग</h5>
                <h5 className='lh-base'><i class="fa-solid fa-calendar-days"></i> &nbsp;  18 फरवरी, 2024</h5>
                <p>लुधियाना, 18 फरवरीः महावीर प्रसाद जन सेवा ट्रस्ट ने भारत विकास परिषद चैरिटेबल ट्रस्ट पंजाब के सहयोग से रिशी नगर स्थित दिव्यांग सहायता केंद्र में निःशुल्क कृत्रिम अंगदान वितरण कैंप का आयोजन किया। कैंप में युवा अग्रदूतो, अनन्या चौधरी, अद्वय चौधरी, शनाया जुनेजा और विराज डालमिआ द्वारा दिव्यांगों की मदद की गई। युवा अग्रदूत ने धन संग्रह आयोजन
                  ....ताकि बिना किसी सहारे के अपने कदमों पर चल सकें
                  दिव्यांगों को 28 निःशुल्क कृत्रिम अंग बाजू, कैलिपर, बैसाखी, ट्राईसाइकिल व ऊंचा सुनने के लिए कानों की मशीन वितरित की गई। कैंप में मुख्य मेहमान के तौर पर पद्मश्री रजनी बैक्टर ने शिरकत की, जबकि तृप्ता डालमिआ व डॉ. आर. एल नारंग विशेष मेहमान के तौर पर उपस्थित रहे। भारत विकास परिषद के अध्यक्ष पंकज जिंदल ने उपस्थित
                  सदस्य । </p>
                <a href='https://epaper.punjabkesari.in/subscription'>https://epaper.punjabkesari.in/subscription</a>
              </div>
            </div>
          </div>


          <div className='row py-4'>
            <div className='col-lg-6 '>
              <img src={news3} alt='' width={580} className='img-fluid border ' />
            </div>
            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
              <div>
                <h5 className='fw-bold'>Hues of India (start-up project) by Ananya Chowdhery Participate in an Maharaja Regency
                </h5>
                <h5 className='lh-base'><i class="fa-solid fa-calendar-days"></i> &nbsp; 5 April, 2023</h5>
                <p>Our young Entrepreneur Ananya Chowdhery selling DIY art kits made inspired from Madhubani Art (Traditional Art and Craft of Bihar) and Placements and Coasters inspired by Lippan Art (Traditional Gujarati Art). Promoting Indian Traditional Art the proceeds to go for charity.</p>
                <a href='https://www.instagram.com/p/CtraUe5A3p1/'>https://www.instagram.com/p/CtraUe5A3p1/
                </a>
              </div>
            </div>
          </div>

          <div className='row py-4'>
            <div className='col-lg-6 '>
              <img src={news2} alt='' width={580} className='img-fluid border ' />
            </div>
            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
              <div>
                <h5 className='fw-bold'>Making best of vacations, these students organise medical camps in Rajasthan</h5>
                <h5 className='lh-base'><i class="fa-solid fa-calendar-days"></i> &nbsp; 28 March, 2023</h5>
                <p>LUDHIANA: While majority of the school students, would like to go for a trip to the hill station  or abroad to have fund time in the vacations, a Ludhiana based class XII student Ananya Chowdhury, 17 of Sat Paul Mittal School and her cousin Smarthya bindlish, 15 of Vivek High School, are making best of their vacations by organizing health camps in Rajasthan.</p>
                <a href='https://timesofindia.indiatimes.com/education/news/making-best-of-vacations-these-students-organise-medical-camps-in-rajasthan/articleshow/99048576.cms?from=mdr'>https://timesofindia.indiatimes.com/education/news
                </a>
              </div>
            </div>
          </div>


          <div className='row py-4'>
            <div className='col-lg-6 '>
              <img src={news1} alt='' width={580} className='img-fluid border ' />
            </div>
            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
              <div>
                <h5 className='fw-bold'>मेहंदीपुर : अंजनी धाम धर्मशाला में एक मुफ्त नेत्र जांच और मोतियाबिंद सर्जरी शिविर का आयोजन किया</h5>
                <h5 className='lh-base'><i class="fa-solid fa-calendar-days"></i> &nbsp;  बुधवार, 15 मार्च 2023</h5>
                <p>मेहंदीपुर, दौसा, राजस्थान - अनन्या चौधरी , सतपॉल मित्तल स्कूल, लुधियाना और सामर्थ्य बिंदलिश, विवेक हाई स्कूल, चंडीगढ़ ने महाबीर प्रसाद डालमिया जन सेवा ट्रस्ट और डॉ. श्रॉफ चैरिटी आई हॉस्पिटल, अलवर के सहयोग से 14 और 15 मार्च 2023 को अंजनी धाम धर्मशाला में एक मुफ्त नेत्र जांच और मोतियाबिंद सर्जरी शिविर का आयोजन किया।</p>
                <a href='https://www.pragatimedia.org/2023/03/blog-post_15.html'>https://www.pragatimedia.org/2023/03/blog-post_15.html</a>
              </div>
            </div>
          </div>

          {/* recognition */}
          <div className='recognition py-5'>
            <h2 className='text-dark fw-bold text-center mb-4 text-decoration-underline'>Recognitions</h2>
            <div className='row'>
              <div className='col-lg-4'>
                <img src={recognition1} className='img-fluid' />
                <h6 className='mt-2 text-center'>Bharat Vikas Parishad, Ludhiana and  Bharat Bhushan Ashu Facilitated by Ex-MLA</h6>
              </div>
              <div className='col-lg-4'>
                <img src={recognition2} className='img-fluid' />
                <h6 className='mt-2 text-center'>Bharat Vikas Parishad, Ludhiana <br /> Annual General Meeting</h6>
              </div>
              <div className='col-lg-4'>
                <img src={recognition3} className='img-fluid' />
                <h6 className='mt-2 text-center'>Prachin Gaushala, Tibba Road, Ludhiana</h6>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default Newscoverage
