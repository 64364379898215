import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCLueFBeL4SduIZNIZNGXem_wtLUDSIvN4",
    authDomain: "mahabir-prasad-dalmia.firebaseapp.com",
    projectId: "mahabir-prasad-dalmia",
    storageBucket: "mahabir-prasad-dalmia.appspot.com",
    messagingSenderId: "555302157212",
    appId: "1:555302157212:web:8bfc6ed0b918540bb52659",
    measurementId: "G-BQV0ZKQ4FK"
  };

  const app = initializeApp(firebaseConfig);
  export const db= getFirestore(app);
