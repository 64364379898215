import React, { useState } from 'react'
import img from '../images/startupHeadder.jpg'
import { GondArt, rangoli, madhubani } from './DataImages'
import ImageModal from './ImageModal'
const Startup = () => {
  const [gondOpen, setGondOpen]= useState(false)
  const [rangoliOpen, setRangoliOpen] =useState(false)
  const [madhubaniOpen, setMadhubaniOpen]= useState(false)
  const [index, setIndex] = useState(0)

  const setGondIndex = (index) => {
    setIndex(index)
    setGondOpen(!rangoliOpen)
  }
  const setRangoliIndex = (index) => {
    setIndex(index)
    setRangoliOpen(!madhubaniOpen)
  }
  const setMadhubaniIndex = (index) => {
    setIndex(index)
    setMadhubaniOpen(!gondOpen)
  }
  return (
    <>
      <div classname="section "  >
        <div className='heading-section  ' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>Fundraiser Startup</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>Fundraiser Startup</a>
          </span>
        </div>
      </div>


      {/* startup */}
      <div className='py-5 position-relative'>
      <ImageModal source={GondArt} index={index} requestToClose={setGondOpen} requestToOpen={gondOpen} />
        <ImageModal source={rangoli} index={index} requestToClose={setRangoliOpen} requestToOpen={rangoliOpen} />
        <ImageModal source={madhubani} index={index} requestToClose={setMadhubaniOpen} requestToOpen={madhubaniOpen} />
        <div className='container '>
          <h2 className='fw-bold text-center mb-4 text-dark'>
            Fundraiser startup
          </h2>
          <h5 className='fw-bold'>Discover the Vibrant Hues of India:</h5>
          <p className='text-secondary'>Our latest fundraising initiative
            a unique opportunity to bring the mesmerizing world of Indian art into your home! Our charity is excited to present the “Indian Art DIY Kits- Hues of India" fundraiser. Immerse yourself in the rich culture and traditions of India while supporting a noble cause.</p><br />
          <h6 className='fw-bold'>Unleash Your Inner Artist -
            <span className='text-secondary fs-6 fw-normal'> He DIY kit is thoughtfully curated for all age groups and makes creating your own masterpiece inspired by iconic Indian art forms of Madhubani and Gond Art very simple. From intricate Madhubani designs to the graceful strokes of Hond Art, these kits will let you experience the magic of India's artistic heritage firsthand.</span></h6><br />
          <h6 className='fw-bold'>Art for a Purpose -
            <span className='text-secondary fw-normal'> By purchasing the DIY Kit, you're not only embarking on a creative journey but also contributing to popularising traditional Indian art forms in the youth of today. Every kit sold directly funds our charity's education initiatives for underprivileged children. Your support will help provide quality education, empowering these young minds to shape a brighter future. Your involvement will help us paint a better tomorrow for the underprivileged youth.</span></h6><br />
          <h5 className='fw-bold'>Shop with Purpose:</h5>
            <p className='text-secondary '> Visit the link below to explore the range of "India Art DIY Kits" and choose the art form that resonates with you. With your support, we can transform artistic expressions into opportunities for those in need.
            </p>

          <a href='https://www.amazon.in/conceptdecreatif'>https://www.amazon.in/conceptdecreatif</a>


          {/* gond art */}
          <div className='eye-camp '>
            <h5 className='text-center fw-bold mb-3'>DIY Self Printing Kit of Gond Art</h5>
            <div className='gallery-box1'>
              {GondArt.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid ' onClick={() => setGondIndex(index)}/>
                </div>
              ))}
            </div>
          </div>

               {/* Madhubani */}
               <div className='eye-camp '>
            <h5 className='text-center fw-bold mb-3'>DIY Self Printing Kit of Madhubani</h5>
            <div className='gallery-box1'>
              {madhubani.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setMadhubaniIndex(index)}/>
                </div>
              ))}
            </div>
          </div>

               {/* rangoli*/}
               <div className='eye-camp'>
            <h5 className='text-center  fw-bold mb-3'>Handmade Rangoli</h5>
            <div className='gallery-box1'>
              {rangoli.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setRangoliIndex(index)}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Startup
