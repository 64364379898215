import React from 'react'

export default function ImageModal({ source, requestToOpen, index, requestToClose }) {
    return (
        <>
            {
                requestToOpen &&
                <div className='w-100 image-modal d-flex justify-content-center align-items-center' onClick={() => requestToClose(!requestToOpen)} >
                    <img src={source[index].img} alt='photu' className='w-50' />
                </div>
            }
        </>
    )
}
