import React from 'react'
import logo from '../images/mahabir prasad dalmia  FINAL.png'
const Footer = () => {
    return (
        <>
    {/* Footer */}
<footer className="text-center text-lg-start bg-light text-dark border ">
  {/* Section: Social media */}
  <section className="p-3 border-bottom border-dark">
{/* Section: Social media */}
<section className="text-center ">
  {/* Facebook */}
  <a className="btn btn-outline-dark  btn-floating m-2 fs-5" href="https://www.facebook.com/people/Mahabir-Prasad-Dalmia-Janseva-Trust/100095280284941/" role="button"><i className="fab fa-facebook-f" /></a> 
  {/* Instagram */}
  <a className="btn btn-outline-dark  btn-floating m-2 fs-5 " href="https://www.instagram.com/mahabir_p_dalmia_janseva_trust/" role="button"><i className="fab fa-instagram" /></a>
  
  {/* gmail */}
  <a  className="btn btn-outline-dark  btn-floating m-2 fs-5"  href='mailto:info@akcxis.com' role="button"><i class="fa-regular fa-envelope"></i></a>

</section>
{/* Section: Social media */}

    {/* Right */}
  </section>
  {/* Section: Social media */}
  {/* Section: Links  */}
  <section className>
    <div className="container text-center text-md-start mt-5">
      {/* Grid row */}
      <div className="row mt-3 ">
        {/* Grid column */}
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          {/* Content */}
        <img src={logo} alt='' className='img-fluid w-75'/>
        </div>
        {/* Grid column */}
        {/* Grid column */}
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          {/* Links */}
          <h5 className="text-uppercase fw-bold mb-4 border-bottom ">
           Links
          </h5>
          <p>
            <a href="/" className="text-reset fs-6 text-decoration-none">Home</a>
          </p>
          <p>
            <a href="young" className="text-reset fs-6 text-decoration-none">Young Volunteers</a>
          </p>
          <p>
            <a href="newscoverage" className="text-reset fs-6 text-decoration-none">News Coverage</a>
          </p>
           <p>
            <a href="contact" className="text-reset fs-6 text-decoration-none">Contact Us</a>
          </p>
        </div>
        {/* Grid column */}
        {/* Grid column */}
        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 ">
          {/* Links */}
          <h5 className="text-uppercase fw-bold mb-4  border-bottom ">
            Activities
          </h5>
          <p>
            <a href="medimpact" className="text-reset fs-6 text-decoration-none">Medimpact</a>
          </p>
          <p>
            <a href="nature" className="text-reset text-center fs-6 text-decoration-none">Nature Ally</a>
          </p>
          <p>
            <a href="loveaction" className="text-reset fs-6 text-decoration-none">Love in Action</a>
          </p>
          <p>
            <a href="education" className="text-reset fs-6 text-decoration-none">Educare</a>
          </p>
          <p>
            <a href="religiousconnect" className="text-reset fs-6 text-decoration-none">Religious Connect</a>
          </p>
          <p>
            <a href="holyabode" className="text-reset fs-6 text-decoration-none">Holy Abode</a>
          </p>
          <p>
            <a href="startup" className="text-reset fs-6 text-decoration-none">Fundraiser Startup</a>
          </p>
        </div>
        {/* Grid column */}
        {/* Grid column */}
        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          {/* Links */}
          <h5 className="text-uppercase fw-bold mb-4 border-bottom ">Contact</h5>
          <p className='fs-6'><i className="fas fa-home me-3" />201, Industrial Area A, Ludhiana, Ludhiana (East), millerganj S.O.</p>
          <p className='fs-6'>
            <i className="fas fa-envelope me-3" />
            dalmiajansevatrust@gmail.com
          </p>
          <p className='fs-6'><i className="fas fa-phone me-3" />+91 98159-45149</p>
          
        </div>
        {/* Grid column */}
      </div>
      {/* Grid row */}
    </div>
  </section>
  {/* Section: Links  */}
  {/* Copyright */}
  <div className="text-center p-4" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
    Copyright 2023. Made by:
    <a className="text-reset fw-bold text-decoration-none" > Ananya Chowdhery and Samarthya Bindlish</a>
  </div>
  {/* Copyright */}
</footer>
{/* Footer */}




        </>
    )
}

export default Footer
