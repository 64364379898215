import React from 'react'
import img from '../images/religiousHeaderImage.jpg'
const Religiousconnect = () => {
    return (
        <>
            {/* heading */}
            <div classname="section "  >
                <div className='heading-section  ' style={{ backgroundImage: `url(${img})` }}>
                    <h1 className='display-1 fw-bold mb-3 text-light'>Religious Connect</h1>
                    <span className='fs-5 fw-bold'>
                        <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home </a> &nbsp;
                        <a className='text-warning text-decoration-none'>Religious Connect</a>
                    </span>
                </div>
            </div>

            {/* religious */}
            <div className='py-5'>
                <div className='container'>
                    <h2 className='fw-bold text-center mb-4 text-dark'>
                        Religious Connect
                    </h2>
                    <p className='text-secondary fw-semibold'>Our trust has installed an idol of revered Lord Hanuman at Upkaar Nagar Mandir. This sacred endeavor aims to bring solace, inspiration, and spiritual strength to all who visit. The Lord Hanuman idol stands as a symbol of devotion, courage, and unwavering faith. It serves as a reminder of the values of selfless service, humility, and dedication that Lord Hanuman exemplified in the epic Ramayana. This idol is not just a religious emblem but a beacon of unity that brings our community together. It provides a serene space for reflection, prayer, and a sense of belonging, fostering harmony among diverse individuals.</p>
                </div>
            </div>

        </>
    )
}

export default Religiousconnect
