import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase.config";


const UserDataCollection= collection(db,'userData')

class services {
    postCountries(data){
        return addDoc(UserDataCollection,data)
    }
    
}

export default new services()