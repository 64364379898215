import React from 'react'
import img from '../images/Young-Voulintera.jpg'
import young1 from '../images/youngImg1.jpg'
import young2 from '../images/young2.jpg'
import young3 from '../images/young3.jpg'
import young4 from '../images/young4.jpg'
import young5 from '../images/young5.jpg'
import './young.css'
const Young = () => {
  return (
    <>
      <div classname="section "  >
        <div className='heading-section  ' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>Young Volunteers</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>Young Volunteers</a>
          </span>
        </div>
      </div>

      {/* Top Volunteering */}
      <div className='Volunteering py-5'>
        <div className='container'>
          <h2 className='text-center fw-bold mb-4 text-decoration-underline text-dark'>Our Volunteers Team</h2>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='Voluntee-box border shadow-lg px-3 py-4 mb-5 bg-body rounded text-center'>
                <div className='d-flex justify-content-around align-items-center'>
                  <img src={young1} alt='' className='img-fluid ' />
                </div>
                <div>
                  <h5 className='fw-bold mt-3'>Ananya Chowdhery</h5>
                  <h6 className=''>Sat Paul Mittal School, Ludhiana</h6>
                  <p className='text-center'><a href='https://www.linkedin.com/posts/ananya-chowdhery-7a5658272_satpaulmittalschool-activity-7088096282652680192-KJ5f/' className='text-reset'><i  class="fa-brands fa-linkedin fs-5"></i></a>&nbsp; &nbsp; <a href='https://www.instagram.com/ananyachowdhery/?igshid=MzRlODBiNWFlZA%3D%3D' className='text-reset'><i class="fa-brands fa-instagram fs-5"></i></a></p>
                  <p className='text-secondary fw-semibold'>Ananya Chowdhery, a brilliant student, orator and an all-rounder, is a passionate Class 12 student of Sat Paul Mittal School dedicated to making a difference. Balancing studies with an active role in a charitable trust, she contributes to various initiatives that uplift the community from conducting workshops, teaching STEM to underprivileged students, to running a start-up for fundraising. She brilliantly led a campaign with her peers for eye awareness in backward areas.  She took the initiative to spread the word on social media and design this website.  Committed to spreading positivity and embracing challenges with enthusiasm, Ananya aims to create a brighter future for all.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='Voluntee-box border shadow-lg px-3 py-4 mb-5 bg-body rounded text-center'>
                <div className='d-flex justify-content-around align-items-center'>
                  <img src={young2} alt='' className='img-fluid ' />
                </div>
                <div>
                  <h5 className='fw-bold mt-3'>Samarthya Bindlish</h5>
                  <h6 className='mb-3'>Vivek High School, Chandigarh</h6>
                  <p className='text-secondary fw-semibold'>Samarthya Bindlish, a class 10th student of Vivek High School Chandigarh, is a sport enthusiast and car aficionado with a research paper on electric cars under his belt. He is deeply engaged in a charitable trust's activities and passionate about making a positive impact, with a strong commitment to helping others. With a strong penchant for coding he has helped in designing this website and demonstrates remarkable leadership and teamwork skills through consistent engagement in various charitable activities like in spreading awareness about eye care in various schools in villages and raising funds for various social causes. He is constantly striving to foster compassion and change through hands-on involvement and inspire fellow students which is quite remarkable.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='Voluntee-box border shadow-lg px-3 py-4 mb-5 bg-body rounded text-center'>
                <div className='d-flex justify-content-around align-items-center'>
                  <img src={young4} alt='' className='img-fluid ' />
                </div>
                <div>
                  <h5 className='fw-bold mt-3'>Advay Chowdhery</h5>
                  <h6 className='mb-3'>Sat Paul Mittal School, Ludhiana</h6>
                  <p className='text-secondary fw-semibold'>Advay Chowdhery, a student of Sat Paul Mittal School Ludhiana, is a passionate 11-year-old making a big impact. An avid reader, and skilled in Lego and robotics, he is dedicated to helping others. Blessed with a high EQ, he is building a better world, one block at a time. Inspired by books and driven to create, he is a young changemaker with a heart for helping others.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='Voluntee-box border shadow-lg px-3 py-4 mb-5 bg-body rounded text-center'>
                <div className='d-flex justify-content-around align-items-center'>
                  <img src={young5} alt='' className='img-fluid ' />
                </div>
                <div>
                  <h5 className='fw-bold mt-3'>Shanaya Juneja</h5>
                  <h6 className='mb-3'>Sat Paul Mittal School, Ludhiana</h6>
                  <p className='text-secondary fw-semibold'>Shanaya Juneja, a student of Sat Paul Mittal School Ludhiana, is a 10-year-old on a mission. She is committed to give back to the society, is a book enthusiast, and a nature lover who cares deeply for animals. Her world revolves around crafting captivating stories that come to life. With a heart for giving and a mind full of creativity, she is making a difference and sharing tales from the heart.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='Voluntee-box border shadow-lg px-3 py-4 mb-5 bg-body rounded text-center'>
                <div className='d-flex justify-content-around align-items-center'>
                  <img src={young3} alt='' className='img-fluid ' />
                </div>
                <div>
                  <h5 className='fw-bold mt-3'>Viraj Dalmia</h5>
                  <h6 className='mb-3'>Sat Paul Mittal School, Ludhiana</h6>
                  <p className='text-secondary fw-semibold'>Viraj Dalmia, a Student of Sat Paul Mittal School, Ludhiana, is an avid reader and badminton enthusiast who fiddles around with coding in his free time. Viraj has an extremely high EQ as he has been exposed to numerous social economic activities initiated under Mahabir Prasad Dalmia Trust. At the tender age of 8 years he was one of the core team members for an eye camp which required  him to go village schools for awareness sessions. He never shy's away from lending a helping hand wherever needed.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
export default Young
