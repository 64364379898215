import React, { useState } from 'react'
import img from '../images/Love-in-Action.jpg'
import { chabil, craftActivity, summerCamp } from './DataImages'
import bullEye1 from '../LoveAction-Image/Bulls eye (Fundraiser) stall- Dec/1.jpg'
import bullEye2 from '../LoveAction-Image/Bulls eye (Fundraiser) stall- Dec/2.jpg'
import clio1 from '../LoveAction-Image/CLIO- Orisson Hospital, Play Area for Kids/1.jpg'
import clio2 from '../LoveAction-Image/CLIO- Orisson Hospital, Play Area for Kids/2.jpg'
import ImageModal from './ImageModal'
const Loveaction = () => {
  const [chabilOpen, setChabilOpen]= useState(false)
  const [craftOpen , setCraftOpen]= useState(false)
  const [summerOpen, setSummerOpen]= useState(false)
  const [bulleye1Open, setBulleye1Open]= useState(false)
  const [bulleye2Open, setBulleye2Open]= useState(false)
  const [index, setIndex] = useState(0)

  const setChabilIndex = (index) =>{
   setIndex(index)
    setChabilOpen(!craftOpen)
  }
  const setCraftIndex = (index)=>{
    setIndex(index)
    setCraftOpen(!chabilOpen)
  }
  const setSummerIndex = (index)=>{
    setIndex(index)
    setSummerOpen(!craftOpen)
  }
  const setBulleye1Index = (index)=>{
    setIndex(index)
    setBulleye1Open(!craftOpen)
  }
  const setBulleye2Index = (index)=>{
    setIndex(index)
    setBulleye2Open(!craftOpen)
  }
  return (
    <>
      <div classname="section "  >
        <div className='heading-section' style={{ backgroundImage: `url(${img})` }}>
          <h1 className='display-1 fw-bold mb-3 text-light'>Love in Action</h1>
          <span className='fs-5 fw-bold'>
            <a href='/' className='text-light text-decoration-none'><i class="fa-solid fa-house"></i> Home / </a> &nbsp;
            <a className='text-warning text-decoration-none'>Love in Action</a>
          </span>
        </div>
      </div>

      {/* love in action */}
      <div className='py-5 position-relative'>
      <ImageModal source={chabil} index={index} requestToClose={setChabilOpen} requestToOpen={chabilOpen} />
        <ImageModal source={craftActivity} index={index} requestToClose={setCraftOpen} requestToOpen={craftOpen} />
        <ImageModal source={summerCamp} index={index} requestToClose={setSummerOpen} requestToOpen={summerOpen} />
        <ImageModal source={bullEye1} index={index} requestToClose={setBulleye1Open} requestToOpen={bulleye1Open} />
        <ImageModal source={bullEye2} index={index} requestToClose={setBulleye2Open} requestToOpen={bulleye2Open} />
        <div className='container'>
          <h2 className='text-center fw-bold mb-4 text-dark'>Love in Action</h2>
          <p className='text-secondary fw-semibold'>Our compassionate charity has been actively involved in various meaningful initiatives. They displaying their care by collecting food grains for the 'Home for Boys Jamalpur', led the Covid Shield Initiative by producing and distributing reusable shields to front-line workers. They also participated in fundraising efforts for Covid-affected individuals by setting up a stall at a fair. Their commitment extended to volunteering at Book Nook, a camp aimed at enriching the lives of underprivileged children in nearby villages.<br /><br />
            In addition, their support during the pandemic included a donation to feed cows. Furthermore, they fostered creativity and growth among underprivileged children by organizing art and craft workshops. They also embarked on a toy collection drive and helped to create a play area in “CLiO Mother and Child Institute” bringing untold joy to the little patients. Through these diverse endeavors, this charity has demonstrated a commendable dedication to serving and uplifting the community.</p>



          {/*chabil  */}
          <div className='eye-camp py-4'>
            <h4 className='text-center fw-bold mb-4  '>Chabil, Chandigarh - June 2023</h4>
            <div className='gallery-box1'>
              {chabil.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setChabilIndex(index)}/>
                </div>
              ))}
            </div>
          </div>
    {/*craftActivity  */}
    <div className='eye-camp py-4'>
            <h4 className='text-center fw-bold mb-4  '>Craft Activity - Do Good Foundation, Ludhiana- July 2022</h4>
            <div className='gallery-box1'>
              {craftActivity.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setCraftIndex(index)}/>
                </div>
              ))}
            </div>
          </div>
          {/*summerCamp  */}
          <div className='eye-camp py-4'>
            <h4 className='text-center fw-bold mb-4  '>The Summer Smiles Camp - Saanjh and Aas Ehsaas - June 2022</h4>
            <div className='gallery-box1'>
              {summerCamp.map((item, index) => (
                <div className='image-box1'>
                  <img src={item.img} alt='' className='img-fluid' onClick={() => setSummerIndex(index)}/>
                </div>
              ))}
            </div>
          </div>

      

          <div className='row py-3'>
            <div className='col-lg-6'>
              {/*orisson  */}
              <div className=''>
                <h4 className='text-center fw-bold mb-4  '>CLIO- Orisson Hospital,<br/> Play Area for Kids - January 2022</h4>
                <div className='row'>
                  <div className='col-lg-6'>
                  <img src={clio1} alt='' width={2000} className='img-fluid' />
                  </div>
                  <div className='col-lg-6'>
                  <img src={clio2} alt='' width={2000} className='img-fluid' />
                  </div>
                
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              {/*bull eye  */}
              <div className=''>
                <h4 className='text-center fw-bold mb-4  '>Bulls Eye (Fundraiser) Stall <br/> December 2021</h4>
                <div className='row'>
                  <div className='col-lg-6'>
                  <img src={bullEye1} alt='' width={2000} className='img-fluid' />
                  </div>
                  <div className='col-lg-6'>
                  <img src={bullEye2} alt='' width={2000} className='img-fluid' />
                  </div>
                
                </div>
              </div>
            </div>
          </div>






        </div>
      </div>

    </>

  )
}

export default Loveaction
