import image1 from '../medimpact-Image/Eye Camp/1.JPG'
import image2 from '../medimpact-Image/Eye Camp/2.JPG'
import image3 from '../medimpact-Image/Eye Camp/3.JPG'
import image4 from '../medimpact-Image/Eye Camp/4.JPG'
import image5 from '../medimpact-Image/Eye Camp/5.JPG'
import image6 from '../medimpact-Image/Eye Camp/6.JPG'
import image7 from '../medimpact-Image/Eye Camp/7.JPG'
import image8 from '../medimpact-Image/Eye Camp/8.JPG'
import image9 from '../medimpact-Image/Eye Camp/9.JPG'
import image10 from '../medimpact-Image/Eye Camp/10.JPG'
import image11 from '../medimpact-Image/Eye Camp/11.jpg'
import image12 from '../medimpact-Image/Eye Camp/12.JPG'
import image13 from '../medimpact-Image/Eye Camp/13.JPG'
import image14 from '../medimpact-Image/Eye Camp/14.JPG'
import image15 from '../medimpact-Image/Eye Camp/15.JPG'
import image16 from '../medimpact-Image/Eye Camp/16.jpg'
import image17 from '../medimpact-Image/Eye Camp/17.jpg'
import image18 from '../medimpact-Image/Eye Camp/18.JPG'
import image19 from '../medimpact-Image/Eye Camp/19.JPG'
import image20 from '../medimpact-Image/Eye Camp/20.jpg'
import image21 from '../medimpact-Image/Eye Camp/21.jpg'
import image22 from '../medimpact-Image/Eye Camp/22.jpg'
import image23 from '../medimpact-Image/Eye Camp/23.JPG'
import image24 from '../medimpact-Image/Eye Camp/24.JPG'
import image25 from '../medimpact-Image/Eye Camp/25.JPG'
import image26 from '../medimpact-Image/Eye Camp/26.JPG'
import image27 from '../medimpact-Image/Eye Camp/27.jpg'
import image28 from '../medimpact-Image/Eye Camp/28.jpg'
import image29 from '../medimpact-Image/Eye Camp/29.jpg'
import image30 from '../medimpact-Image/Eye Camp/30.jpg'


// prosthetic camp 
import prostheticimage1 from '../medimpact-Image/Prosthetic Camp/1.JPG'
import prostheticimage2 from '../medimpact-Image/Prosthetic Camp/2.JPG'
import prostheticimage3 from '../medimpact-Image/Prosthetic Camp/3.JPG'
import prostheticimage4 from '../medimpact-Image/Prosthetic Camp/4.JPG'
import prostheticimage5 from '../medimpact-Image/Prosthetic Camp/5.JPG'
import prostheticimage6 from '../medimpact-Image/Prosthetic Camp/6.JPG'
import prostheticimage7 from '../medimpact-Image/Prosthetic Camp/7.JPG'
import prostheticimage8 from '../medimpact-Image/Prosthetic Camp/8.JPG'
import prostheticimage9 from '../medimpact-Image/Prosthetic Camp/9.JPG'
import prostheticimage10 from '../medimpact-Image/Prosthetic Camp/10.JPG'
import prostheticimage11 from '../medimpact-Image/Prosthetic Camp/11.JPG'
import prostheticimage12 from '../medimpact-Image/Prosthetic Camp/12.JPG'
import prostheticimage13 from '../medimpact-Image/Prosthetic Camp/13.JPG'
import prostheticimage14 from '../medimpact-Image/Prosthetic Camp/14.JPG'
import prostheticimage15 from '../medimpact-Image/Prosthetic Camp/15.JPG'
import prostheticimage16 from '../medimpact-Image/Prosthetic Camp/16.JPG'
import prostheticimage17 from '../medimpact-Image/Prosthetic Camp/17.JPG'
import prostheticimage18 from '../medimpact-Image/Prosthetic Camp/18.JPG'
import prostheticimage19 from '../medimpact-Image/Prosthetic Camp/19.JPG'
import prostheticimage20 from '../medimpact-Image/Prosthetic Camp/20.JPG'
import prostheticimage21 from '../medimpact-Image/Prosthetic Camp/21.JPG'
import prostheticimage22 from '../medimpact-Image/Prosthetic Camp/22.JPG'
import prostheticimage23 from '../medimpact-Image/Prosthetic Camp/23.JPG'
import prostheticimage24 from '../medimpact-Image/Prosthetic Camp/24.JPG'
import prostheticimage25 from '../medimpact-Image/Prosthetic Camp/25.jpg'
import prostheticimage26 from '../medimpact-Image/Prosthetic Camp/26.jpg'
import prostheticimage27 from '../medimpact-Image/Prosthetic Camp/27.jpg'
import prostheticimage28 from '../medimpact-Image/Prosthetic Camp/28.jpg'
import prostheticimage29 from '../medimpact-Image/Prosthetic Camp/29.jpg'
import prostheticimage30 from '../medimpact-Image/Prosthetic Camp/30.jpg'

// limp camp 
import limpcamp1 from '../medimpact-Image/limp camp/1.png'
import limpcamp2 from '../medimpact-Image/limp camp/2.png'
import limpcamp3 from '../medimpact-Image/limp camp/3.png'
import limpcamp4 from '../medimpact-Image/limp camp/4.png'
import limpcamp5 from '../medimpact-Image/limp camp/5.png'
import limpcamp6 from '../medimpact-Image/limp camp/6.png'
import limpcamp7 from '../medimpact-Image/limp camp/7.png'
import limpcamp8 from '../medimpact-Image/limp camp/8.png'
import limpcamp9 from '../medimpact-Image/limp camp/9.png'
import limpcamp10 from '../medimpact-Image/limp camp/10.png'
import limpcamp11 from '../medimpact-Image/limp camp/11.png'
import limpcamp12 from '../medimpact-Image/limp camp/12.png'
import limpcamp13 from '../medimpact-Image/limp camp/13.png'
import limpcamp14 from '../medimpact-Image/limp camp/14.png'
import limpcamp15 from '../medimpact-Image/limp camp/15.png'


// Nature Ally----> Free Vaccination for street dog
import freeVaccination1 from '../NatureAlly-Image/Free Vaccinations for Street Dogs/1.jpg'
import freeVaccination2 from '../NatureAlly-Image/Free Vaccinations for Street Dogs/2.jpg'
import freeVaccination3 from '../NatureAlly-Image/Free Vaccinations for Street Dogs/3.jpg'
import freeVaccination4 from '../NatureAlly-Image/Free Vaccinations for Street Dogs/4.jpg'
import freeVaccination5 from '../NatureAlly-Image/Free Vaccinations for Street Dogs/5.jpg'

// Nature Ally----> freeing birds
import birds1 from '../NatureAlly-Image/Freeing Caged Birds/1.jpg'
import birds2 from '../NatureAlly-Image/Freeing Caged Birds/2.jpg'
import birds3 from '../NatureAlly-Image/Freeing Caged Birds/3.jpg'
import birds4 from '../NatureAlly-Image/Freeing Caged Birds/4.jpg'
import birds5 from '../NatureAlly-Image/Freeing Caged Birds/5.jpg'

// Nature Ally----> vrindavan
import vrindavan1 from '../NatureAlly-Image/Govind Godham, Vrindavan/1.jpg'
import vrindavan2 from '../NatureAlly-Image/Govind Godham, Vrindavan/2.jpg'
import vrindavan3 from '../NatureAlly-Image/Govind Godham, Vrindavan/3.jpg'
import vrindavan4 from '../NatureAlly-Image/Govind Godham, Vrindavan/4.jpg'
import vrindavan5 from '../NatureAlly-Image/Govind Godham, Vrindavan/5.jpg'
// import vrindavan5 from '../NatureAlly-Image/Govind Godham, Vrindavan/5.jpg'

// Nature Ally----> gaushala
import gaushala1 from '../NatureAlly-Image/Prachin Gaushala, Part-II, Tibba Road, Ludhiana/1.jpg'
import gaushala4 from '../NatureAlly-Image/Prachin Gaushala, Part-II, Tibba Road, Ludhiana/4.jpg'
import gaushala5 from '../NatureAlly-Image/Prachin Gaushala, Part-II, Tibba Road, Ludhiana/5.jpg'
import gaushala6 from '../NatureAlly-Image/Prachin Gaushala, Part-II, Tibba Road, Ludhiana/6.jpg'
import gaushala8 from '../NatureAlly-Image/Prachin Gaushala, Part-II, Tibba Road, Ludhiana/8.jpg'


// love an action--->bulls eye
import bullEye1 from '../LoveAction-Image/Bulls eye (Fundraiser) stall- Dec/1.jpg'
import bullEye2 from '../LoveAction-Image/Bulls eye (Fundraiser) stall- Dec/2.jpg'

// love an action--->chabil
import chabil1 from '../LoveAction-Image/Chabil, Chandigarh/1.jpg'
import chabil2 from '../LoveAction-Image/Chabil, Chandigarh/2.jpg'
import chabil3 from '../LoveAction-Image/Chabil, Chandigarh/3.jpg'
import chabil4 from '../LoveAction-Image/Chabil, Chandigarh/4.jpg'
import chabil5 from '../LoveAction-Image/Chabil, Chandigarh/5.jpg'

// love an action--->clio
import orisson1 from '../LoveAction-Image/CLIO- Orisson Hospital, Play Area for Kids/1.jpg'
import orisson2 from '../LoveAction-Image/CLIO- Orisson Hospital, Play Area for Kids/2.jpg'


// love an action--->craft activity
import craftActivity1 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/1.jpg'
import craftActivity2 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/2.jpg'
import craftActivity3 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/3.jpg'
import craftActivity4 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/4.jpg'
import craftActivity5 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/5.jpg'
import craftActivity6 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/6.jpg'
import craftActivity7 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/7.jpg'
import craftActivity8 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/8.jpg'
import craftActivity9 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/9.jpg'
import craftActivity10 from '../LoveAction-Image/Craft activity-Do Good Foundation, Ludhiana- July 2022/10.jpg'

// love an action--->summer camp
import summerCamp1 from '../LoveAction-Image/The Summer Smiles Camp- Saanjh and Aas Ehsaas/1.jpg'
import summerCamp2 from '../LoveAction-Image/The Summer Smiles Camp- Saanjh and Aas Ehsaas/2.jpg'
import summerCamp3 from '../LoveAction-Image/The Summer Smiles Camp- Saanjh and Aas Ehsaas/3.jpg'
import summerCamp4 from '../LoveAction-Image/The Summer Smiles Camp- Saanjh and Aas Ehsaas/4.jpg'
import summerCamp5 from '../LoveAction-Image/The Summer Smiles Camp- Saanjh and Aas Ehsaas/5.jpg'


// HOLY ABODE
import holy1 from '../Holy Abode/1.jpg'
import holy2 from '../Holy Abode/2.jpg'
import holy3 from '../Holy Abode/3.jpg'
import holy4 from '../Holy Abode/4.jpg'
import holy5 from '../Holy Abode/5.jpg'


// startup---> Gond art
import GondArt1 from '../Fundraiser Stratup/Gond  Art/1.jpg'
import GondArt2 from '../Fundraiser Stratup/Gond  Art/2.png'
import GondArt3 from '../Fundraiser Stratup/Gond  Art/3.jpg'
import GondArt4 from '../Fundraiser Stratup/Gond  Art/4.jpg'
import GondArt5 from '../Fundraiser Stratup/Gond  Art/5.jpg'

// startup---> madhubani 
import madhibani1 from '../Fundraiser Stratup/Madhubani/1.png'
import madhibani2 from '../Fundraiser Stratup/Madhubani/2.png'
import madhibani3 from '../Fundraiser Stratup/Madhubani/3.png'
import madhibani4 from '../Fundraiser Stratup/Madhubani/4.png'
import madhibani5 from '../Fundraiser Stratup/Madhubani/5.png'

// startup--> rangoli
import rangoli1 from '../Fundraiser Stratup/rangoli/1.png'
import rangoli2 from '../Fundraiser Stratup/rangoli/2.png'
import rangoli3 from '../Fundraiser Stratup/rangoli/3.png'
import rangoli4 from '../Fundraiser Stratup/rangoli/4.png'
import rangoli5 from '../Fundraiser Stratup/rangoli/5.png'
// eye camp
const eyeCamp = [{ img: image1 },

{
    img: image2
},
{
    img: image3
},

{
    img: image4
},
{
    img: image5
},
{
    img: image6
},
{
    img: image7
},
{
    img: image8
},
{
    img: image9
},
{
    img: image10
},
{
    img: image11
},
{
    img: image12
},

{
    img: image13
},
{
    img: image14
},
{
    img: image15
},
{
    img: image16
},
{
    img: image17
},
{
    img: image18
}
    ,

{
    img: image19
},
{
    img: image20
},
{
    img: image21
},
{
    img: image22
},
    ,
{
    img: image23
},
{
    img: image24
},
{
    img: image25
},
{
    img: image26
},
{
    img: image27
}, {
    img: image28
}, {
    img: image29
}
    , {
    img: image30
}
]

// limpcamp
const limpCamp =[{
    img: limpcamp1
},
{
    img: limpcamp2
},
{
    img: limpcamp3
},
{
    img: limpcamp4
},
{
    img: limpcamp5
},
{
    img: limpcamp6
},
{
    img: limpcamp7
},
{
    img: limpcamp8
},
{
    img: limpcamp9
},
{
    img: limpcamp10
},
{
    img: limpcamp11
},
{
    img: limpcamp12
},
{
    img: limpcamp13
},
{
    img: limpcamp14
},
,
{
    img: limpcamp15
}
]
// prosthetic camp
const prostheticCamp = [{
    img: prostheticimage1
},

{
    img: prostheticimage2
},
{
    img: prostheticimage3
},

{
    img: prostheticimage4
},
{
    img: prostheticimage5
},
{
    img: prostheticimage6
},
{
    img: prostheticimage7
},
{
    img: prostheticimage8
},
{
    img: prostheticimage9
},
{
    img: prostheticimage10
},
{
    img: prostheticimage11
},
{
    img: prostheticimage12
},

{
    img: prostheticimage13
},
{
    img: prostheticimage14
},
{
    img: prostheticimage15
},
{
    img: prostheticimage16
},
{
    img: prostheticimage17
},
{
    img: prostheticimage18
}
    ,

{
    img: prostheticimage19
},
{
    img: prostheticimage20
},
{
    img: prostheticimage21
},
{
    img: prostheticimage22
},
    ,
{
    img: prostheticimage23
},
{
    img: prostheticimage24
},
{
    img: prostheticimage25
},
{
    img: prostheticimage26
},
{
    img: prostheticimage27
}, {
    img: prostheticimage28
}, {
    img: prostheticimage29
}
    , {
    img: prostheticimage30
}
]

// nature ally--->Free Vaccination for street dog
const Vaccination = [{
    img: freeVaccination1
}, {
    img: freeVaccination2
},
{
    img: freeVaccination3
},
{
    img: freeVaccination4
},
{
    img: freeVaccination5
}

]

// nature ally--->freeing birds
const birds = [{
    img: birds1
},
{
    img: birds2
},
{
    img: birds3
},
{
    img: birds4
},
{
    img: birds5
}
]

// Nature Ally----> vrindavan
const vrindavan = [{
    img: vrindavan5
},
{
    img: vrindavan2
},
{
    img: vrindavan3
},
{
    img: vrindavan4
},
{
    img: vrindavan1
}
]

//  Nature Ally----> gaushala
const gaushala = [{
    img: gaushala1
},
{
    img: gaushala4
},
{
    img: gaushala5
},
{
    img: gaushala6
},

{
    img: gaushala8
},
]

// love an action--->bulls eye
// const bullEye =[{
//     img:bullEye1
// },
// {
//     img:bullEye2
// }]

// love an action--->chabil
const chabil = [{
    img: chabil1
},
{
    img: chabil2
},
{
    img: chabil3
},
{
    img: chabil4
},
{
    img: chabil5
},
]

// love an action--->clio
// const orisson =[{
//     img: orisson1
// },{
//     img:orisson2
// }]


// love an action --->craft activity
const craftActivity = [{
    img: craftActivity1
}, {
    img: craftActivity2
}, {
    img: craftActivity3
}, {
    img: craftActivity4
}, {
    img: craftActivity5
}, {
    img: craftActivity6
}, {
    img: craftActivity7
}, {
    img: craftActivity8
}, {
    img: craftActivity9
}, {
    img: craftActivity10
}
]

// love an action ----> summer camp
const summerCamp = [{
    img: summerCamp1
}, {
    img: summerCamp2
}, {
    img: summerCamp3
}, {
    img: summerCamp4
}, {
    img: summerCamp5
},
]

// holy abode
const Holy = [{
    img: holy1
}, {
    img: holy2
}, {
    img: holy3
}, {
    img: holy4
}, {
    img: holy5
},
]


// startup--> Gond art
const GondArt = [{
    img: GondArt1
},
{
    img: GondArt2
},
{
    img: GondArt3
},
{
    img: GondArt4
},
{
    img: GondArt5
},
]

// startup--> madhubani'
const madhubani = [{
    img: madhibani1
}, {
    img: madhibani2
}, {
    img: madhibani3
}, {
    img: madhibani4
}, {
    img: madhibani5
},
]

// startup ----> rangoli
const rangoli = [{
    img: rangoli1
}, {
    img: rangoli2
}, {
    img: rangoli3
}, {
    img: rangoli4
}, {
    img: rangoli5
},]
export { eyeCamp, prostheticCamp, Vaccination, birds, vrindavan, gaushala, chabil, craftActivity, summerCamp, Holy, GondArt, madhubani, rangoli, limpCamp }