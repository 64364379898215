import React, { useState } from 'react'
import slide1 from '../images/slide1.jpg'
import slide2 from '../images/slide2.JPG'
import slide3 from '../images/slide4.jpg'
import slide4 from '../images/slide3.JPG'
import aboutImg from '../images/Padam Dalmia.jpg'
import aboutImg2 from '../images/Tripta.jpg'
import healthcare from '../images/healthcare.png'
import goshala from '../images/goshala1.png'
import education from '../images/Education.png'
import love from '../images/Love-in-Action.png'
import religious from '../images/Religious.png'
import holy from '../images/Holy-Abode.png'
import startup from '../images/Fundraiser-Startup.png'
import video1 from '../video/video1 (2).mp4'
import video2 from '../video/video6.mp4'
import video3 from '../video/video3.mp4'
import video4 from '../video/video4.mp4'
import video5 from '../video/video5.mp4'
import image1 from '../images/1.jpg'
import image2 from '../images/2.jpg'
import image3 from '../images/3.jpg'
import image6 from '../images/4.jpg'
import image5 from '../images/5.jpg'
import image4 from '../images/6.jpg'
import image7 from '../images/7.jpg'
import image8 from '../images/8.jpg'

import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './home.css'

const Home = () => {

    return (
        <>
            {/* craousel */}
            <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval='2000'>
                        <img src={slide4} className="d-block w-100 pic " alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval={'3000'}>
                        <img src={slide2} className="d-block w-100 pic " alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval={'3000'}>
                        <img src={slide1} className="d-block w-100 pic " alt="..." />
                    </div>
                    <div className="carousel-item" data-bs-interval={'3000'}>
                        <img src={slide3} className="d-block w-100 pic " alt="..." />
                    </div>
                  
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            {/* About */}
            <div className='about py-5'>
                <div className='container'>
                    <h2 className='text-center fw-bold text-uppercase mb-4 text-decoration-underline text-dark' >About Us</h2>
                    <div className='row g-3'>
                        <div className='col-lg-2 d-flex justify-content-center align-items-center'>
                            <div>
                                <img src={aboutImg} alt='' className='img-fluid ' />
                                <h6 className='text-center lh-base fw-bold'>Late Sh. Padam Kr. Dalmia</h6>
                            </div>

                        </div>
                        <div className='col-lg-4 d-flex justify-content-center align-items-center'>
                            <div>
                                <h6>
                                    Late Shri Padam Kumar Dalmia was the founder visionary of our charitable trust. He possessed a profound commitment to serve the society. A self-made man with a clear and inspiring vision, he paved the way in creating impactful programs and initiatives. Through his unwavering dedication, innovative thinking, and compassionate leadership, he has guided the trust in its mission to bring about meaningful transformations and help those in need. He has left an ever-lasting legacy of serving others before self which is spiritedly carried on by the trust and his family.
                                </h6>
                            </div>
                        </div>
                        <div className='col-lg-2 d-flex justify-content-center align-items-center'>
                            <div>
                                <img src={aboutImg2} alt='' className='img-fluid border' />
                                <h6 className='text-center lh-base fw-bold'>Smt. Tripta Dalmia</h6>
                            </div>

                        </div>
                        <div className='col-lg-4 d-flex justify-content-center align-items-center'>
                            <div>
                                <h6>
                                    Smt. Tripta Dalmia, wife of Late Shri Padam Kumar Dalmia and Co-founder of the charitable trust, is a very pious and emphatic person who has played an active and vital role within the organization. She has dedicatedly worked towards improving the lives of those the charity serves. She has seamlessly carried on his legacy and is continuing the impactful work they began together. With a deep understanding of his vision and a commitment to the cause, she ensures that his dreams and the charity's mission live on, touching the lives of countless individuals and communities.
                                </h6>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* our imapact */}
            <div className='py-5 impact-bg ' >
                <div className='container  '>
                    <h2 className='fw-bold text-center text-uppercase mb-5 text-decoration-underline text-dark'>Our Impact</h2>
                    <div className='row imapct '>
                        <div className='col-lg-3 col-md-6 '>
                            <div className='text-center text-light p-3'>
                                <h1 className='fw-bold display-1 '>15+</h1>
                                <h2 className='fw-bold py-3 '>VILLAGES</h2>
                                <h6 className='text-secondary fw-semibold'>The trust's presence in 15+ villages has brought transformative change, improving education access and healthcare facilities, uplifting local livelihoods and fostering community cohesion.</h6>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='text-center p-3'>
                                <h1 className='fw-bold display-1 '>3+</h1>
                                <h2 className='fw-bold py-3 '>CITIES</h2>
                                <h6 className='text-secondary fw-semibold'>Our trust's initiatives across 3+ cities have provided marginalized communities with essential services, skill development and fostering a sense of empowerment, resulting in enhanced quality of life and social progress.</h6>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='text-center p-3'>
                                <h1 className='fw-bold display-1 '>10+</h1>
                                <h2 className='fw-bold py-3 '>PROJECTS</h2>
                                <h6 className='text-secondary fw-semibold'>Completing 10+ impactful projects, the trust has left an indelible mark by addressing crucial issues such as education, healthcare and environmental sustainability, significantly improving the well-being of communities it serves.</h6>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='text-center p-3'>
                                <h1 className='fw-bold display-1'>15,000+</h1>
                                <h2 className='fw-bold py-3 '>PEOPLES</h2>
                                <h6 className='text-secondary fw-semibold'>Touching the lives of 15,000+ individuals, the trust's efforts have created a ripple effect of positive change, Spanning medical camp, education, start-up initiative and animal care for count less life's and families. </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* our programs */}
            <div className=' py-5 programs'>
                <div className='container'>
                    <h2 className='text-center fw-bold text-uppercase mb-5 text-decoration-underline text-dark'>Our Programmes</h2>
                    <div className='row  g-3'>
                        <div className='col-lg-3  '>
                            <div className='   border shadow-lg p-4 bg-body rounded'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={healthcare} alt='' className='img-fluid w-50' />
                                </div>
                                <div className='text-center'>
                                    <h5 className='mt-3  fw-bold text-uppercase mb-3'>Medimpact</h5>
                                    <h6 className='mb-3 text-secondary'>Restoring hope and changing lives. Our charity extends a helping hand through free medical camps in Mehandipur, Dausa where even day to day...</h6>
                                    <div className='text-center'>
                                        <a href='medimpact'>Read More</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-3  '>
                            <div className='border shadow-lg p-4 bg-body rounded'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={goshala} alt='' className='img-fluid w-50' />
                                </div>
                                <div className='text-center'>
                                    <h5 className='mt-3  fw-bold text-uppercase mb-3'>Nature Ally</h5>
                                    <h6 className='mb-3 text-secondary'>Championing animal welfare with compassion. Our dedicated charity has constructed safe havens, like shelters, sheds in gaushala, providing our animal...</h6>
                                    <div className='text-center'>
                                        <a href='/nature'>Read More</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-3  '>
                            <div className='border shadow-lg p-4 bg-body rounded'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={love} alt='' className='img-fluid w-50' />
                                </div>
                                <div className='text-center'>
                                    <h5 className='mt-3  fw-bold text-uppercase mb-3'>Love in Action</h5>
                                    <h6 className='mb-3 text-secondary'>Our compassionate charity has been actively involved in various meaningful initiatives. They displayed their care by collecting food grains... </h6>
                                    <div className='text-center'>
                                        <a href='loveaction'>Read More</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-3'>
                            <div className='border shadow-lg p-4 bg-body rounded'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={education} alt='' className='img-fluid w-50' />
                                </div>
                                <div className='text-center'>
                                    <h5 className='mt-3 text-dark fw-bold text-uppercase mb-3 '>Education</h5>
                                    <h6 className='mb-3 text-secondary'>Illuminating pathways through education, our charity passionately sponsors underprivileged students, opening doors to knowledge and a brighter future...</h6>
                                    <div className='text-center'>
                                        <a href='/education'>Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='border shadow-lg p-4 bg-body rounded'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={religious} alt='' className='img-fluid w-50' />
                                </div>
                                <div className='text-center'>
                                    <h5 className='mt-3  fw-bold text-uppercase mb-3'>Religious Connect</h5>
                                    <h6 className='mb-3 text-secondary'>Our trust has installed an idol of revered Lord Hanuman at Upkaar Nagar Mandir. This sacred endeavor aims to bring solace, inspiration and spiritual...</h6>
                                    <div className='text-center'>
                                        <a href='religiousconnect'>Read More</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-3  '>
                            <div className='border shadow-lg p-4 bg-body rounded'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={holy} alt='' className='img-fluid w-50' />
                                </div>
                                <div className='text-center'>
                                    <h5 className='mt-3  fw-bold text-uppercase mb-3'>Holy Abode</h5>
                                    <h6 className='mb-3 text-secondary'>Restful haven for pilgrims, crafted with care Mehandipur Balaji (Dausa), in Rajasthan is a center of reverence and faith for people not only in the country...</h6>
                                    <div className='text-center'>
                                        <a href='/holyabode'>Read More</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-3  '>
                            <div className='border shadow-lg p-4 bg-body rounded'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={startup} alt='' className='img-fluid w-50' />
                                </div>
                                <div className='text-center'>
                                    <h5 className='mt-3  fw-bold text-uppercase mb-3'>Fundraiser Startup</h5>
                                    <h6 className='mb-3 text-secondary'>Discover the Vibrant Hues of India: Our latest fundraising initiative
                                        a unique opportunity to bring the mesmerizing world of Indian art into your home...</h6>
                                    <div className='text-center'>
                                        <a href='/startup'>Read More</a>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            {/* our gallery */}
            <div className='gallery py-4 bg-light'>
                <div className='container-fluid '>
                    <h2 className='text-center fw-bold mb-5 text-uppercase text-decoration-underline text-dark'>Our gallery</h2>
                    <div className='gallery-box'>
                        <div className='image-box'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <a className='lightbox-image' href='medimpact'>
                                        <img src={image1} alt='' className='img-fluid' />
                                    </a>
                                </figure>
                                {/* <div className="overlay">
                                    <a href="#" class="icon" title="User Profile">
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className='image-box'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <a className='lightbox-image' href='medimpact'> 
                                        <img src={image3} alt='' className='img-fluid' />
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div className='image-box'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <a className='lightbox-image' href='loveaction'>
                                        <img src={image2} alt='' className='img-fluid' />
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div className='image-box'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <a className='lightbox-image' href='medimpact'>
                                        <img src={image4} alt='' className='img-fluid' />
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div className='image-box'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <a className='lightbox-image' href='loveaction'>
                                        <img src={image5} alt='' className='img-fluid' />
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div className='image-box'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <a className='lightbox-image' href='education'>
                                        <img src={image6} alt='' className='img-fluid' />
                                    </a>
                                </figure>
                            </div>
                        </div>

                        <div className='image-box'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <a className='lightbox-image' href='nature'>
                                        <img src={image7} alt='' className='img-fluid' />
                                    </a>
                                </figure>
                            </div>
                        </div>

                        <div className='image-box'>
                            <div className='inner-box'>
                                <figure className='image'>
                                    <a className='lightbox-image'>
                                        <img src={image8} alt='' className='img-fluid' />
                                    </a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* gallery footer */}
            <div className='gallery-footer py-5'>
                <div className='container py-5'>
                    <div className='row g-3'>
                        <div className='col-lg-9'>
                            <h1 className='fw-bold mb-4'>Serving Humanity One Step at a Time</h1>
                            {/* <h5>There are many variations of passages of Lorem Ipsum available, but the majority have lorem</h5> */}
                        </div>
                        <div className='col-lg-3 d-flex justify-content-center align-items-center'>
                            <div className='text-center'>
                                <a href='/medimpact' className='btn btn-outline-light rounded-pill px-4 py-3 fs-5 fw-bold '>View Full Gallery</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* success story */}
            <div className='py-4 '>
                <div className='container-fluid'>
                    <h2 className='text-dark text-center fw-bold text-uppercase mb-5  text-decoration-underline '>Stories in motion</h2>
                    <center>
                        <div className='row row-cols-lg-5  '>
                            <div className='col col-md-6 col-sm-12'>
                                <video  controls>
                                    <source src={video2} type="video/mp4" />
                                </video>
                            </div>
                            <div className='col col-md-6 col-sm-12'>
                                <video controls>
                                    <source src={video4} type="video/mp4" />
                                </video>
                            </div>
                            <div className='col col-md-6 col-sm-12'>
                                <video controls >
                                    <source src={video1} type="video/mp4" />
                                </video>
                            </div>
                            <div className='col col-md-6 col-sm-12'>
                                <video controls>
                                    <source src={video3} type="video/mp4" />
                                </video>
                            </div>
                            <div className='col col-md-6 col-sm-12 '>
                                <video controls  >
                                    <source src={video5} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </>
    )
}

export default Home 
